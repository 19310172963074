import * as React from "react"
import { useEffect } from "react";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql, Link } from "gatsby";
import '../../styles/projelerimiz.css';
import arrow from "../../images/projects-arrow.svg"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Projects = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.querySelector("body").classList = ["projects"];
    document.querySelector("html").classList = [""];
  }, [])
  return (
    <Layout>
      <Seo title="Projelerimiz" />
      <div className="mt-40 relative flex items-center justify-center md:mt-28">
        <div className="max-w-full overflow-hidden wrapper">
          <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl md:mb-0">{t("ProjectsMain")}</p>
            <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl md:mb-0">{t("ProjectsMain")}</p>
          </div>
        </div>
        <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto md:text-3xl -mt-10 md:-mt-1">{t("ProjectsMain")}</h1>
      </div>
      <div className="w-11/12 mx-auto mt-5 flex items-center flex-col">
        <div className="w-[2px] bg-default h-16 md:h-10 mb-5"></div>
        <Link to="./restaurant-kafe" className="relative justify-center transition-all duration-300 w-4/12 group font-first text-4xl md:w-11/12 md:text-xl text-default mb-5 flex items-center">{t("RestoranKafe")}<img src={arrow} alt="arrow" className="absolute right-0 md:scale-75 transition-all duration-300 group-hover:-right-5" /></Link>
        <div className="w-[2px] bg-default h-16 md:h-10 mb-5"></div>
        <Link to="./penthouse-daire" className="relative justify-center transition-all duration-300 w-4/12 group font-first text-4xl md:w-11/12 md:text-xl text-default mb-5 flex items-center">{t("PenthouseDaire")}<img src={arrow} alt="arrow" className="absolute right-0 md:scale-75 transition-all duration-300 group-hover:-right-5" /></Link>
        <div className="w-[2px] bg-default h-16 md:h-10 mb-5"></div>
        <Link to="./diger-projeler" className="relative justify-center transition-all duration-300 w-4/12 group font-first text-4xl md:w-11/12 md:text-xl text-default mb-5 flex items-center">{t("OtherProjectsMain")}<img src={arrow} alt="arrow" className="absolute right-0 md:scale-75 transition-all duration-300 group-hover:-right-5" /></Link>
        <div className="w-[2px] bg-default h-16 md:h-10 mb-5"></div>
        <Link to="./villa" className="relative justify-center transition-all duration-300 w-4/12 group font-first text-4xl md:w-11/12 md:text-xl text-default mb-5 flex items-center">{t("VillaMain")}<img src={arrow} alt="arrow" className="absolute right-0 md:scale-75 transition-all duration-300 group-hover:-right-5 " /></Link>
        <div className="w-[2px] bg-default h-64 md:h-20"></div>
      </div>
    </Layout>
  )
}

export default Projects;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;